<template>
  <b-container class="about">
    <h1 class="text--white">Plaid OAuth Account Integration</h1>

    <b-overlay>
      <PlaidLink
        :company-id="companyId"
        button-title="Add a New Bank Account"
      ></PlaidLink>
    </b-overlay>
  </b-container>
</template>
<script>
import StorageService from '@/services/StorageService'
import PlaidLink from '@/components/plaid/Link'

export default {
  name: 'Plaid',
  components: { PlaidLink },
  data () {
    return {
      loading: false,
      companyId: null
    }
  },
  mounted () {
    this.init()
  },
  methods: {
    async init () {
      this.user = StorageService.get('plaidLinkTokenCompanyId')
    }
  }
}
</script>
